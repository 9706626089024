<template>
  <div>

    <div class="is-flex is-flex-col">
      <div class="is-flex"
        style="width: 100%">

        <div class="table-header"
          style="width: 44%;">
          Preferred Suppliers
        </div>

        <div class="table-header has-text-left"
          style="width: 22%;">
          Location
        </div>

        <div class="table-header has-text-left"
          style="width: 12%;">
          Rating
        </div>

        <div class="table-header has-text-left"
          style="width: 10%;">
          Status
        </div>

        <div class="table-header has-text-right"
          style="width: 12%;">
          Add to preferred
        </div>

      </div>
    </div>

    <div v-for="(item) in innerValue.filter(s=> s.supplierTypeId == '4' && s.isPreferred != '1')"
      :key="item.id">
      <div class="is-flex is-flex-col">

        <div class="is-flex table-row"
          style="width: 100%;">

          <div style="width: 4%; padding: 5px;">
            <div v-if="isNormal"
              class="pretty p-icon p-curve p-smooth m-0 p-bigger">
              <input type="checkbox"
                v-model="item.isChecked"
                @change="selectSupplier(item)">
              <div class="state">
                <i class="icon mdi mdi-check" />
                <label />
              </div>
            </div>
            <div v-if="!isNormal"
              class="pretty p-icon p-round p-smooth is-size-10">
              <input v-model="item.isChecked"
                name="supplier-modal-aftermarket-suppliers"
                :id="'supplier-modal-aftermarket-suppliers-' + item.id"
                :value="true"
                @change="selectSupplierDirect(item)"
                type="radio">
              <div class="state">
                <i class="icon mdi mdi-check" />
                <label />
              </div>
            </div>
          </div>
          <div class=""
            style="width: 40%; padding: 5px;">
            {{ item.name }}
          </div>

          <div class=""
            style="width: 22%; padding: 5px;">
            {{ item.suburb + ' ' + item.postcode }}
          </div>
          <div class=""
            style="width: 12%; padding: 5px;">
            <a v-for="(i,rating) in 5"
              :key="rating"
              class="icon has-text-grey-light is-small is-inverted">
              <span :class="{'icon has-text-primary': i <= item.rating}">
                <i class="mdi mdi-star" />
              </span>
            </a>
          </div>
          <div class=""
            style="width: 10%; padding: 5px;">
            {{ item.isLocked === 1 ? 'Locked' : 'Quoting' }}
          </div>

          <div class="has-text-centered"
            style="width: 12%; padding: 5px;">
            <a class="icon has-text-grey-light is-small is-inverted"
              @click.prevent="addPreferred(item, $event)"
              data-tooltip="Remove from Preferred Suppliers list">
              <span class="icon is-medium">
                <i class="mdi mdi-star mdi-20px" />
              </span>
            </a>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
//import _cloneDeep from 'lodash/cloneDeep'
import { SuggestedSupplierColumns } from '../../columns'
// import { PartsCheckService } from '../../services'

export default {
  name: 'PartsCheckAftermarketSuppliersModal',
  props: {
    value: null,
    vehicleMakeId: null,
    isNormal: true
  },
  data() {
    return {
      innerValue: null
    }
  },
  computed: {
    columns() {
      return SuggestedSupplierColumns
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = newVal
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  methods: {
    addPreferred(id, event) {
      this.$emit('add-to-preferred', id)
    },
    selectSupplier(item) {
      this.$emit('select-supplier', item)
    },
    selectSupplierDirect(item) {
      this.$emit('selectSupplierDirectOrder', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px !important;
  font-size: 14px;
}
.table-header {
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
}
</style>