<template>
  <base-modal-ex v-if="isActive"
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="50"
    :min-height="50"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>Edit Default Options</span>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="tile is-ancestor is-parent">
        <div class="tile">
          <div class="tile is-parent is-half">
            <article class="tile is-child box detail-page-tile">
              <div class="columns is-gapless">
                <div class="column">
                  <div class="field">
                    <label class="label mb-4">Quote Information</label>
                    <div class="field-body">

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSendClaimNo">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Send Claim No.</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSendPartNo">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Send Part No.</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSendPartNotes">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Send Part Notes</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isAutoSelectPhotos">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Auto Select Photos</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="is-divider" />
                  </div>
                  <div class="field">
                    <label class="label">Select Default Item Type</label>
                    <div class="field-body">

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSelectItemTypeOEM">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>OEM</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSelectItemTypeAfterMarket">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Aftermarket</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSelectItemTypeUsed">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Used</label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSelectItemTypeReconditioned">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Reconditioned</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="is-divider" />
                  </div>
                  <div class="field">
                    <label class="label">Select Default Timeframe</label>
                    <div class="field-body">
                      <div class="field">
                        <div>
                          <label class="mr-2">Your default timeframe to receive quote is: </label>
                          <select v-model="defaultSetting.defaultTimeFrame">
                            <option v-for="(timeFrame, index) in defaultTimeFrames"
                              :key="index"
                              :value="timeFrame">
                              {{ timeFrame }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="is-divider" />
                  </div>
                  <div class="field">
                    <label class="label">Select Default Notifications</label>
                    <div class="field-body">
                      <div class="field">
                        <label class="mr-2">Select User to be notified: </label>
                        <select class="select"
                          v-model="defaultSetting.userToBeNotified">
                          <option v-for="(userName, index) in users"
                            :key="index"
                            :value="userName">
                            {{ userName }}
                          </option>
                        </select>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isSmsNotifications">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Receive SMS Notifications</label>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="pretty p-icon p-curve p-smooth p-bigger">
                          <input type="checkbox"
                            v-model="defaultSetting.isEmailNotifications">
                          <div class="state">
                            <i class="icon mdi mdi-check" />
                            <label>Receive Email Notifications</label>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="is-divider" />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-primary"
        @click="Save()"
        data-tooltip="Close">Save</button>
      <button class="button tooltip is-tooltip-topright"
        @click="close()">Close</button>
    </footer>
  </base-modal-ex>
</template>
<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { PartsCheckService } from '../../services'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PricePartsEditDefaultOptionsModal',
  components: {
    BaseModalEx
  },
  props: {
    value: null,
    vehicle: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-file-export'
    },
    enableAttach: {
      type: Boolean,
      default: true
    },
    defaultEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: false,
      defaultTimeFrames: ['1 hour', '2 hours'],
      users: ['Heena', 'Bee', 'Oshi'],
      defaultSetting: null
    }
  },
  watch: {
    defaultSetting: {
      handler: function (newVal, oldVal) {
        this.defaultSetting = newVal
      },
      deep: true
    }
  },
  created() {
    this.defaultSetting = _cloneDeep(this.value)
  },
  mounted() {
    this.isActive = this.active || false
  },
  methods: {
    async Save() {
      await PartsCheckService.savePartsCheckDefaultSettings(this.defaultSetting)
      this.$emit('input', this.defaultSetting)
      this.isActive = false
      this.$emit('update:active', false)
    },
    close() {
      this.defaultSetting = this.value
      this.isActive = false
      this.$emit('update:active', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>
