<template>
  <div class="column">
    <div class="tile is-ancestor is-parent ">
      <div class="tile ">
        <div class="tile is-parent is-vertical p-0 m-0 ">
          <article class="tile is-child box p-3">
            <div class="columns full-quote-page-height">
              <div class="column is-4 br-gray-2">
                <parts-list v-if="detailInfo.editDefaultOption"
                  @request-type="requestType"
                  v-model="partsList"
                  :default-options="detailInfo.editDefaultOption" />
              </div>
              <div class="column is-4 br-gray-2">
                <parts-suppliers v-if="states!= null"
                  v-model="suppliers"
                  :states="states"
                  :vehicle-make-id="vehicleMakeId"
                  :preferred-suppliers-prop="preferredSuppliers"
                  :is-normal="isNormal"
                  :specialist-supplier="specialistSupplier"
                  :max-suppliers-count="maxSuppliersCount"
                  @get-selected-suppliers="getSelectedSuppliers"
                  @supplier-list="supplierList"
                  @get-supplier-list="getSupplierList" />
              </div>
              <div class="column is-4">
                <price-parts-details v-if="marginSettings !== null && timeSlots.length > 0"
                  v-model="detailInfo"
                  :time-slots="timeSlots"
                  :margin-settings="marginSettings"
                  :image-ids="imageIds"
                  :quote-id="value.quoteId"
                  :is-normal="isNormal"
                  @create-price-parts-request="createPricePartsRequest"
                  @onCancel="onCancel" />
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PartsCheckService } from '../services'
import { PricePartsInfoModel, PartsCheckDataLinesModel, PricePartsDetailInfo, PricePartsVehicleBodyStayle, PricePartsRequestModel } from '@/classes/viewmodels'
import { PartsList, PricePartsDetails, PartsSuppliers } from './PriceParts'
import { QuoteNotificationMixin, QuoteAssessmentMixin } from '../mixins'
import QuoteRoutes from '../route-types'
import { EventHubTypes } from '@/enums'

export default {
  name: 'QuotePriceParts',
  components: { PartsList, PricePartsDetails, PartsSuppliers },
  mixins: [QuoteNotificationMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    vehicle: null,
    selectedUnorderedPartsPartsControl: {
      type: Array,
      default: () => []
    },
    pricePartsReturnRoute: null
  },
  data: () => {
    return {
      pricePartsInfo: new PricePartsInfoModel(),
      partsList: null,
      vehicleMakeList: null,
      vehicleMakeId: null,
      isNormal: true,
      suppliers: null,
      selectedImageIds: null,
      detailInfo: null,
      marginSettings: null,
      partLines: null,
      dataLines: null,
      states: null,
      stateId: null,
      suppliers: null,
      preferredSuppliers: [],
      selectedSuppliers: [],
      timeSlots: [],
      selectedOtherSuppliers: [],
      defaultOptions: null,
      specialistSupplier: [],
      maxSuppliersCount: 10
    }
  },
  computed: {},
  watch: {
    detailInfo: {
      handler: function (newVal, oldVal) {
        this.detailInfo = newVal
      },
      deep: true
    }
  },
  created() {
    this.imageIds = this.value.imageIds
    this.quoteId = this.value.quoteId
    this.getSupplierList()

    // If selected parts are passed, use them (parts control), otherwise use the parts from the quote (quote builder)
    if (this.selectedUnorderedPartsPartsControl.length > 0) {
      this.partsList = this.selectedUnorderedPartsPartsControl
    } else {
      this.partsList = this.value.parts.filter((p) => p.deleted == false && p.partStatus != 'Pord' && (p.quantityOrdered === 0 || p.quantityReturned > 0))
    }

    if (this.value.quoteAssessments.length > 0) {
      const aItems = this.getItemsAssessments(this.partsList, this.value.quoteAssessments)
      let unAuthParts = []
      this.partsList.forEach((item) => {
        let found = aItems.filter((p) => p.quoteItemId == item.quoteItemId)
        if (found.length == 0) {
          unAuthParts.push(item)
        }
      })
      this.partsList = unAuthParts
    }

    this.detailInfo = new PricePartsDetailInfo()
  },
  methods: {
    async createPricePartsRequest() {
      this.$showSpinner('Submitting part items to PartsCheck')
      const title = 'Price Parts'
      this.createDataLines()
      // this.selectedSuppliers = []
      // for (let i = 0; i < this.preferredSuppliers.length; i++) {
      //   if (this.preferredSuppliers[i]?.isChecked === true) this.selectedSuppliers.push(this.preferredSuppliers[i].id)
      // }
      // for (let i = 0; i < this.selectedOtherSuppliers.length; i++) {
      //   this.selectedSuppliers.push(this.selectedOtherSuppliers[i].id)
      // }
      // for (let i = 0; i < this.specialistSupplier.length; i++) {
      //   const found = this.selectedSuppliers.find((i) => i === this.specialistSupplier[i])
      //   if (!found) if (this.specialistSupplier[i]?.isChecked === true) this.selectedSuppliers.push(this.specialistSupplier[i].id)
      // }

      const maxSupplierCount = this.maxSuppliersCount ?? 10

      let selectedSuppliersId = []
      for (let i = 0; i < this.selectedSuppliers.length; i++) {
        if (this.selectedSuppliers[i]?.isChecked === true) selectedSuppliersId.push(this.selectedSuppliers[i].id)
      }

      if (this.selectedSuppliers.length === 0)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Select at least one supplier from the list')
      else if (this.selectedSuppliers.length > maxSupplierCount)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Can not select more than ' + maxSupplierCount + ' suppliers')
      else if (this.partLines === null || this.partLines.length === 0)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Select at least one part item from the list')
      else {
        this.selectedImageIds = this.detailInfo.SelectedPhotos
        this.dataLines = this.partLines
        const bodyStyle = new PricePartsVehicleBodyStayle()
        bodyStyle.BodyStyle = ''
        bodyStyle.quoteType = this.isNormal ? 'quote' : 'purchase'
        bodyStyle.supplierType = 'usesupplierid'
        bodyStyle.supplyComment = this.detailInfo.Notes
        bodyStyle.supplyDate = this.detailInfo.SelectedDate
        bodyStyle.timeRequired = this.detailInfo.SelectedTime
        bodyStyle.supplierID = selectedSuppliersId //this.selectedSuppliers
        bodyStyle.claimNo = this.detailInfo.editDefaultOption.isSendClaimNo ? this.value.claimNo : ''
        bodyStyle.selectedMargin = this.detailInfo.selectedMargin
        let request = new PricePartsRequestModel(bodyStyle)
        request.SelectedImageIds = this.detailInfo.SendPhotos == true ? this.selectedImageIds : []
        request.VehicleBodyStyle = bodyStyle
        request.SelectedParts = this.dataLines
        console.log(request.VehicleBodyStyle)
        console.log(selectedSuppliersId)
        const submitted = await PartsCheckService.submitPriceParts(this.value.quoteId, request)
        console.log(submitted)
        if (submitted > 0) {
          this.$notification.openNotificationWithType('success', title, request.SelectedParts.length + ' Items have been sent for pricing')
          this.$eventHub.$emit(EventHubTypes.EntityReload)
          this.$router.push({ path: QuoteRoutes.QuotePartsControl.path })
        } else this.$notification.openNotificationWithType('danger', title, 'Error submitting price request to PartsCheck')
      }
      this.$hideSpinner()
    },
    createDataLines() {
      let dataLines = []
      let partLine = new PartsCheckDataLinesModel()
      for (let i = 0; i < this.partsList.length; i++) {
        const line = this.partsList[i]
        if (line?.isChecked) {
          let partType = ''
          partType =
            'JSON:{"partTypes":{"New":"' +
            line.isOem +
            '","Used":"' +
            line.isUsed +
            '","Aftrm":"' +
            line.isAftrm +
            '","Recon":"' +
            line.isRecon +
            '"},"AddlQuoteNo":""}'
          partLine = new PartsCheckDataLinesModel()
          ;(partLine.QuoteNo =
            this.value.subQuote == 0
              ? this.value.prefix + this.value.quoteNo + this.value.suffix
              : this.value.prefix + this.value.quoteNo + this.value.suffix + '/' + this.value.subQuoteNo),
            (partLine.LineNo = line.quoteItemId)
          ;(partLine.AddlQuoteNo = partType), (partLine.LineQty = line.itemQuantity)
          partLine.ItemType = line.mark
          partLine.PartNR = this.detailInfo.editDefaultOption.isSendPartNo ? line.partNo : ''
          partLine.TradePrice = ''
          partLine.Description = line.itemDesc
          dataLines.push(partLine)
        }
      }
      this.partLines = dataLines
    },
    requestType(value) {
      this.isNormal = value
    },
    supplierList(value) {
      this.selectedOtherSuppliers = value
    },
    getImageIds(imageIds) {
      this.selectedImageIds = imageIds
    },
    async getSupplierList() {
      var suppInfo = await PartsCheckService.getAllSupplierAndVehicleMakeList()
      this.pricePartsInfo = suppInfo
      this.vehicleMakeList = this.pricePartsInfo.vehicleMakes
      this.marginSettings = this.pricePartsInfo.insurers
      this.vehicleMakeId = await this.getVehicleMakeId()
      this.states = this.pricePartsInfo.states
      this.timeSlots = this.pricePartsInfo.timeSlots
      this.stateId = this.getStateId()

      this.maxSuppliersCount = parseInt(this.pricePartsInfo.config.maxSupplierPerQuote, 10)
      let allSuppliers = []

      if (!this.vehicleMakeId) {
        this.$notification.openNotificationWithType('danger', 'Error', 'Vehicle make not found')
        return
      } else {
        this.preferredSuppliers = this.pricePartsInfo.suppliers.filter((s) => s.vehicleMakeId == this.vehicleMakeId)
        allSuppliers = await PartsCheckService.getSuppliers(this.stateId, this.vehicleMakeId)
      }

      this.suppliers = allSuppliers
      this.preferredSuppliers.filter((i) => i.supplierTypeId !== '3').forEach((i) => (i.isChecked = true))
      this.specialistSupplier = this.suppliers.filter((s) => s.isSpecial === '1')
    },
    getStateId() {
      return this.states.find((s) => s.nameShort == this.$company.info.stateCode).id
    },
    async getVehicleMakeId() {
      const make = this.vehicle.model.make.toLowerCase().trim()
      let id = ''
      for (let i = 0; i < this.vehicleMakeList.length; i++) {
        const currentVehicle = this.vehicleMakeList[i]
        if (currentVehicle.name.toLowerCase() === make) {
          id = currentVehicle.id
        }
      }
      if (id === '') {
        for (let n = 0; n < this.vehicleMakeList.length; n++) {
          const alternativeName = this.vehicleMakeList[n].alternativeNames.split(',')
          for (let i = 0; i < alternativeName.length; i++) {
            if (alternativeName[i].toLowerCase() === make) {
              id = this.vehicleMakeList[n].id
            }
          }
        }
      }
      return id
    },
    onCancel() {
      // if no back route is provided, go back to the quote selections page
      let routeName = this.pricePartsReturnRoute ?? QuoteRoutes.QuotePart.name
      this.$router.push({
        name: routeName
      })
    },
    getSelectedSuppliers(value) {
      this.selectedSuppliers = []
      this.selectedSuppliers = value
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.br-gray-2 {
  border-right: 1px solid #adb5bd;
}

.full-quote-page-height {
  height: fit-content;
  // 52px for the navbar and 52px for the quote header, 20px for the padding
  min-height: calc(100vh - 52px - 52px - 20px) !important;
}
</style>