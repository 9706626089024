<template>
  <div v-if="value">
    <div>
      <div class="mb-3 has-text-weight-bold"
        style="height: 20px">Price Parts</div>
      <div class="columns"
        style="height: 50px">
        <div class="column">
          <div class="field">
            <label class="has-text-info has-text-weight-bold">Parts</label>
          </div>
        </div>
        <div class="columns"
          style="align-items: center">
          <div class="column p-0 mr-3">
            <div class="field">
              <p>Quote Type: </p>
            </div>
          </div>
          <div class="column p-0">
            <div class="field">
              <div class="pretty p-default p-round p-smooth is-size-9">
                <input id="chk-pretty-request-type"
                  v-model="isNormal"
                  name="chk-pretty-request-type"
                  :value="true"
                  @change="requestType"
                  type="radio">
                <div class="state p-primary">
                  <label class="is-size-9">Normal</label>
                </div>
              </div>
            </div>
          </div>
          <div class="column p-0">
            <div class="field">
              <div class="pretty p-default p-round p-smooth is-size-9">
                <input id="chk-pretty-request-type"
                  v-model="isNormal"
                  name="chk-pretty-request-type"
                  :value="false"
                  @change="requestType"
                  type="radio">
                <div class="state p-primary">
                  <label class="is-size-9">Direct Purchase</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- display parts list -->
    <div class="is-divider divider-color m-0 mb-2" />
    <div class="is-flex is-flex-col">
      <div class="is-flex"
        style="width: 100%">
        <div style="width: 6%; padding: 5px;">
          <div class="pretty p-icon p-curve p-smooth m-0 p-bigger is-size-6">
            <input type="checkbox"
              v-model="isTitleCheck"
              @change="titleCheckboxChanged(isTitleCheck)">
            <div class="state">
              <i class="icon mdi mdi-check" />
              <label />
            </div>
          </div>
        </div>

        <div class="item-desc"
          style="font-weight: bold;">
          Description
        </div>
        <!--
          <div class="item-type-header item-type-oem-header">
            <label>
              <input type="checkbox"
                style="display: none"
                v-model="isItemTypeSelected">
              <span class="text is-clickable"
                @click="selectPartType('oem')"> OEM </span>
            </label>
          </div>

          <div class="item-type-header item-type-aftrm-header">
            <label>
              <input type="checkbox"
                style="display: none"
                v-model="isItemTypeSelected">
              <span class="text is-clickable"
                @click="selectPartType('aftrm')">Aftrm</span>
            </label>
          </div>

          <div class="item-type-header item-type-used-header">
            <label>
              <input type="checkbox"
                style="display: none"
                v-model="isItemTypeSelected">
              <span class="text is-clickable"
                @click="selectPartType('used')">Used</span>
            </label>
          </div>

          <div class="item-type-header item-type-recon-header">
            <label>
              <input type="checkbox"
                style="display: none"
                v-model="isItemTypeSelected">
              <span class="text is-clickable"
                @click="selectPartType('recon')">Recon</span>
            </label>
          </div> -->

        <div class="item-qty has-text-centered"
          style="font-weight: bold;">Qty</div>
      </div>
    </div>

    <div class="scroll-container">
      <div v-for="(item, index) in innerPartsList"
        :key="item.quoteItemId">
        <div class="is-flex is-flex-col">

          <div class="is-flex table-row"
            :style="{ 'border': item.partStatus != 'Exp' && item.partStatus != 'Imp'? '1px solid #54B700' : ''}"
            style="width: 100%">

            <div style="width: 6%; padding: 5px;">
              <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                <input type="checkbox"
                  v-model="item.isChecked"
                  :id="'report' + index"
                  :name="'report' + index"
                  @change="selectParts(item.isChecked)">
                <div class="state">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
            </div>

            <div class="item-desc">
              {{ item.itemDesc }}
            </div>

            <!-- <div class="item-type-header"
                style="width: 8%; ">
                <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input type="checkbox"
                    v-model="item.isOem">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>

              <div class="item-type-header"
                style="width: 9%;">
                <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input type="checkbox"
                    v-model="item.isAftrm">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>

              <div class="item-type-header"
                style="width: 9%;">
                <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input type="checkbox"
                    v-model="item.isUsed">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>

              <div class="item-type-header"
                style="width: 9%;">
                <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input type="checkbox"
                    v-model="item.isRecon">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div> -->

            <div class="item-qty">
              {{ item.itemQuantity }}
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { PricePartsColumns } from '../../columns'
import _cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'PartsList',
  components: {},
  props: {
    value: null,
    defaultOptions: null
  },
  data: () => {
    return {
      isNormal: true,
      innerPartsList: null,
      isTitleCheck: true,
      isItemTypeSelected: true
    }
  },

  computed: {
    columns() {
      return PricePartsColumns
    }
  },
  watch: {
    innerPartsList: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    isItemTypeSelected: {
      handler: function (newVal, oldVal) {
        this.isItemTypeSelected = newVal
      },
      deep: true
    },
    defaultOptions: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.defaultOptions = newVal
          for (let i = 0; i < this.innerPartsList.length; i++) {
            this.isItemTypeSelected = !this.isItemTypeSelected
            if (this.innerPartsList[i].isChecked) {
              this.innerPartsList[i].isOem = this.defaultOptions.isSelectItemTypeOEM ? true : false
              this.innerPartsList[i].isUsed = this.defaultOptions.isSelectItemTypeUsed ? true : false
              this.innerPartsList[i].isAftrm = this.defaultOptions.isSelectItemTypeAfterMarket ? true : false
              this.innerPartsList[i].isRecon = this.defaultOptions.isSelectItemTypeReconditioned ? true : false
            }
          }
        }
      },
      deep: true
    }
  },
  mounted() {},
  created() {
    this.innerPartsList = _cloneDeep(this.value)
    this.innerPartsList.sort((a, b) => {
      const aStatus = a.partStatus
      const bStatus = b.partStatus
      if (aStatus === null && bStatus !== null) return -1
      if (aStatus !== null && bStatus === null) return 1
      if (aStatus === '') return -1
      if (bStatus === '') return 1
      if (aStatus < bStatus) return -1
      if (aStatus > bStatus) return 1

      const aSortno = a.sortNo
      const bSortno = b.sortno

      return aSortno - bSortno
     // return 0
    })
    for (let i = 0; i < this.innerPartsList.length; i++) {
      if (this.innerPartsList[i].partStatus === null || this.innerPartsList[i].partStatus === '') this.innerPartsList[i].isChecked = true
      else {
        this.innerPartsList[i].isChecked = false
        this.isTitleCheck = false
      }
      if ((this.innerPartsList[i].isChecked && this.innerPartsList[i].partStatus !== 'Exp') || this.isTitleCheck === true) {
        this.innerPartsList[i].isOem = this.defaultOptions.isSelectItemTypeOEM ? true : false
        this.innerPartsList[i].isUsed = this.defaultOptions.isSelectItemTypeUsed ? true : false
        this.innerPartsList[i].isAftrm = this.defaultOptions.isSelectItemTypeAfterMarket ? true : false
        this.innerPartsList[i].isRecon = this.defaultOptions.isSelectItemTypeReconditioned ? true : false
      } else {
        this.innerPartsList[i].isOem = false
        this.innerPartsList[i].isUsed = false
        this.innerPartsList[i].isAftrm = false
        this.innerPartsList[i].isRecon = false
      }
    }
    this.isItemTypeSelected = false
  },
  methods: {
    async requestType() {
      this.$emit('request-type', this.isNormal)
    },
    titleCheckboxChanged(value) {
      if (value) this.innerPartsList.forEach((i) => (i.isChecked = true))
      else this.innerPartsList.forEach((i) => (i.isChecked = false))

      for (let i = 0; i < this.innerPartsList.length; i++) {
        if ((this.innerPartsList[i].isChecked && this.innerPartsList[i].partStatus !== 'Exp') || this.isTitleCheck === true) {
          this.innerPartsList[i].isOem = true
          this.innerPartsList[i].isUsed = true
          this.innerPartsList[i].isAftrm = true
          this.innerPartsList[i].isRecon = true
        } else {
          this.innerPartsList[i].isOem = false
          this.innerPartsList[i].isUsed = false
          this.innerPartsList[i].isAftrm = false
          this.innerPartsList[i].isRecon = false
        }
      }
    },
    selectPartType(type) {
      this.isItemTypeSelected = !this.isItemTypeSelected
      for (let i = 0; i < this.innerPartsList.length; i++) {
        if (type === 'oem') this.innerPartsList[i].isOem = this.innerPartsList[i].isChecked ? !this.innerPartsList[i].isOem : false
        else if (type === 'aftrm') this.innerPartsList[i].isAftrm = this.innerPartsList[i].isChecked ? !this.innerPartsList[i].isAftrm : false
        else if (type === 'used') this.innerPartsList[i].isUsed = this.innerPartsList[i].isChecked ? !this.innerPartsList[i].isUsed : false
        else if (type === 'recon') this.innerPartsList[i].isRecon = this.innerPartsList[i].isChecked ? !this.innerPartsList[i].isRecon : false
      }
    },
    selectParts(value) {
      this.isItemTypeSelected = !this.isItemTypeSelected
      for (let i = 0; i < this.innerPartsList.length; i++) {
        if (this.innerPartsList[i].isChecked) {
          this.innerPartsList[i].isOem = true
          this.innerPartsList[i].isUsed = true
          this.innerPartsList[i].isAftrm = true
          this.innerPartsList[i].isRecon = true
        } else {
          this.innerPartsList[i].isOem = false
          this.innerPartsList[i].isUsed = false
          this.innerPartsList[i].isAftrm = false
          this.innerPartsList[i].isRecon = false
        }
      }
      this.isTitleCheck = this.innerPartsList.find((id) => id.isChecked === false) ? false : true
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  max-height: 70vh;
  overflow: auto;
}
.divider-color {
  border-color: #2196f3;
}
.table-row {
  display: flex;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px !important;
}

.item-type-header {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 4px;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.icon {
  &.is-clickable {
    pointer-events: auto !important;
    cursor: pointer;
  }
}
.scroll-container {
  // full page height - header height - navbar height - buttons and heading height
  max-height: calc(100vh - 52px - 52px - 150px);
  overflow-y: auto;
}
.item-type-oem-header {
  width: 8%;
  text-decoration-color: #2e87ec;
}
.item-type-aftrm-header {
  width: 9%;
  text-decoration-color: #7700ff;
}
.item-type-used-header {
  width: 9%;
  text-decoration-color: #13bb38;
}
.item-type-recon-header {
  width: 9%;
  text-decoration-color: #cf9c0f;
}
.item-qty {
  width: 10%;
  padding: 5px;
  text-align: center;
}
.item-desc {
  width: 84%;
  padding: 5px;
}
</style>